import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Table } from 'react-bootstrap';

import { backgroundGrey, lightRed } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { grape } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import VerificationStatusIcon from 'components/global_products/detail_view/VerificationStatusIcon';

function ManageProductOutliersTable({
  products,
  selectedList,
  setSelectedList,
  globalProductId,
  setProductEditModalSettings,
}) {
  return (
    <Container>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>
              <Form.Check
                type="checkbox"
                checked={products?.length > 0 && selectedList?.length === products?.length}
                onChange={e => setSelectedList(e.target.checked ? [...products] : [])}
              />
            </th>
            <th>Entity Type</th>
            <th>Entity Name</th>
            <th>SKU</th>
            <th>Product Name</th>
            <th>Core 11</th>
            <th>Pack Size</th>
            <th>Brand</th>
            <th>Average UPB Score</th>
            <th>Cluster</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {products?.length ? (
            products?.map((product, index) => (
              <tr
                key={index}
                className={classNames({
                  [css(styles.rowRed)]: product.cluster_by_correlation === -1,
                })}
              >
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedList?.some(
                      selectedProduct => selectedProduct?.id === product.id,
                    )}
                    onChange={e => {
                      if (e.target.checked) {
                        if (
                          !selectedList.some(
                            selectedProduct =>
                              selectedProduct.id === product.id &&
                              selectedProduct.product_type === product.product_type,
                          )
                        ) {
                          setSelectedList([...selectedList, product]);
                        }
                      } else {
                        setSelectedList(
                          selectedList.filter(selectedProduct => selectedProduct.id !== product.id),
                        );
                      }
                    }}
                  />
                </td>
                <td>{product.entity_type}</td>
                <td>{product.entity_name}</td>
                <td>{product.sku}</td>
                <td>{product.name}</td>
                <td>{product.core_11 || '-'}</td>
                <td>{product.pack_size || '-'}</td>
                <td>{product.brand_name || '-'}</td>
                <td>{product.average_upb_score}%</td>
                <td>
                  {product.cluster_by_correlation !== undefined &&
                  product.cluster_by_correlation !== null
                    ? product.cluster_by_correlation
                    : '-'}
                </td>
                <td className="text-center">
                  <VerificationStatusIcon verificationStatus={product.verification_status} />
                </td>
                <td className="d-flex">
                  <Button
                    variant="link"
                    key={product?.id}
                    className={pointerOnHover}
                    onClick={e => {
                      e.stopPropagation();
                      setProductEditModalSettings({ show: true, product: product });
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="link"
                    className={classNames(css(styles.button), pointerOnHover)}
                    onClick={() => {
                      window.open(
                        `/global-product-detail/${globalProductId}/` +
                          `${product.id}/${product.entity_type.toLowerCase()}`,
                        '_blank',
                      );
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No products found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    backgroundColor: backgroundGrey,
    zIndex: 1,
  },
  tbody: {
    position: 'relative',
  },
  button: {
    color: grape,
  },
  rowRed: {
    backgroundColor: lightRed,
  },
});

ManageProductOutliersTable.propTypes = {
  products: PropTypes.array,
  selectedList: PropTypes.array,
  setSelectedList: PropTypes.func,
  globalProductId: PropTypes.number,
  setProductEditModalSettings: PropTypes.func,
};

export default ManageProductOutliersTable;
