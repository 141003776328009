import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { areYouSure } from 'lib/utils/toast';
import BrandQAEditModal from 'components/manage_brands/BrandQAEditModal';
import { BRANDS_UPDATE_FROM_QA } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';

import usePost from 'lib/hooks/usePost';
function BrandQAViewTableRow({ brand, refetch }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const { postData: editBrand } = usePost(
    BRANDS_UPDATE_FROM_QA,
    () => {
      SwalDialog('success', 'Successfully Updated the Brand', 'Success', 'center');
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while Updating the Brand: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const handleResolve = () => {
    areYouSure(() => {
      editBrand({
        name: brand?.name,
        id: brand?.id,
        resolve: true,
      });
    }, 'Are you sure you want to Resolve the Brand?');
  };
  return (
    <>
      <tr>
        <td>{brand?.name}</td>
        <td>{brand?.global_manufacturer?.name || '-'}</td>
        <td>
          <ul>
            {brand?.similar_brands?.map(similarBrand => (
              <li key={similarBrand.id}>{similarBrand.brand_name}</li>
            ))}
          </ul>
        </td>
        <td>{brand?.verified_by?.name || '-'}</td>
        <td>
          <Button variant="link" onClick={() => setShowEditModal(true)}>
            Edit
          </Button>
          <Button
            variant="link"
            key={brand?.id}
            onClick={() => window.open(`/enrich-products/brand-details/${brand.id}`, '_blank')}
          >
            View
          </Button>
          <Button variant="link" onClick={handleResolve}>
            Resolve
          </Button>
        </td>
        {showEditModal ? (
          <BrandQAEditModal
            onHide={() => setShowEditModal(false)}
            brand={brand}
            refetch={refetch}
          />
        ) : null}
      </tr>
    </>
  );
}

BrandQAViewTableRow.propTypes = {
  brand: PropTypes.object,
  refetch: PropTypes.func,
};

export default BrandQAViewTableRow;
