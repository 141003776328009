import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { Col, Form, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { some, isEqual, reject } from 'lodash';
import { useLocation } from 'react-router-dom';

import { ALL_ATTRIBUTES } from 'lib/networking/endpoints';
import { backgroundGrey2, blue, darkGrey } from 'lib/css/colors';
import { fsaSettings } from 'images/icons/navbar';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ProductListExportContext } from 'components/product_lists/product_export/ProductListExportContainer';
import pointerOnHover from 'lib/css/pointerOnHover';
import { SingleDataStreamViewContext } from 'components/data_streams/SingleDataStreamViewContainer';
import useGet from 'lib/hooks/useGet';
import { CUT_DRY_STATIC_PRODUCT_URL, IMAGE_URL } from 'lib/constants';

const DEFAULT_SELECTED_COLUMNS = [
  { attribute_name: CUT_DRY_STATIC_PRODUCT_URL, id: null, isGlobalAttribute: null },
  { attribute_name: IMAGE_URL, id: null, isGlobalAttribute: null },
];

function AttributeSplitView() {
  const location = useLocation();
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { setAttributes, selectedAttributes, setSelectedAttributes, setIsAGlobalAttribute } =
    useContext(
      location.pathname.includes('data-streams')
        ? SingleDataStreamViewContext
        : ProductListExportContext,
    );
  const { data: allAttributes = [], loading } = useGet(ALL_ATTRIBUTES);

  useEffect(() => {
    if (allAttributes) {
      const attributesList = allAttributes.concat(DEFAULT_SELECTED_COLUMNS);
      setAttributes(attributesList);
      setFilteredAttributes(attributesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAttributes]);

  const handleSearch = event => {
    const attributesList = allAttributes.concat(DEFAULT_SELECTED_COLUMNS);
    setSearchText(event.target.value);
    if (event.target.value) {
      setFilteredAttributes(
        attributesList.filter(
          attr =>
            attr.attribute_name?.toLowerCase().includes(searchText.toLowerCase()) ||
            attr.global_attribute_name?.toLowerCase().includes(searchText.toLowerCase()),
        ),
      );
    } else {
      setFilteredAttributes(attributesList);
    }
  };
  const handleClick = attr => {
    const isGlobalAttribute = 'global_attribute_name' in attr;
    const attributeName = isGlobalAttribute ? attr.global_attribute_name : attr.attribute_name;
    const uniqKey = attr.id + '_' + attributeName + '_' + isGlobalAttribute;
    const isAlreadySelected = some(selectedAttributes, obj => isEqual(obj.uniqKey, uniqKey));
    if (isAlreadySelected) {
      setSelectedAttributes(reject(selectedAttributes, obj => isEqual(obj.uniqKey, uniqKey)));
    } else {
      setSelectedAttributes([
        ...selectedAttributes,
        {
          uniqKey: uniqKey,
          id: attr.id,
          isGlobalAttribute: isGlobalAttribute,
          attributeName: attributeName,
          columnName: attributeName,
          isRequired: true,
        },
      ]);
    }
  };

  return (
    <div className="px-4 mt-1">
      <Row className="mt-2">
        <Col sm={12} className="mb-4 pl-3">
          <Form.Control
            onChange={handleSearch}
            type="text"
            placeholder="Search Attributes"
            value={searchText}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span
            className={classNames(css(styles.resultsText), 'float-right pr-5')}
          >{`${filteredAttributes.length} Results`}</span>
        </Col>
      </Row>
      <div className={css(styles.scrollableDiv)}>
        {loading ? (
          <Row>
            <Col colSpan={4}>
              <LoadingSpinner short />
            </Col>
          </Row>
        ) : (
          filteredAttributes?.map((attr, index) => (
            <div key={index}>
              <Row
                className={classNames(
                  attr.id + '_' + index === selectedAttributes?.idPlusIndex &&
                    css(styles.selectedRow),
                  'pl-3',
                  pointerOnHover,
                )}
                onClick={() => {
                  handleClick(attr);
                  setIsAGlobalAttribute('global_attribute_name' in attr);
                }}
              >
                <Col
                  sm={12}
                  className={classNames(
                    'global_attribute_name' in attr && css(styles.globalRow),
                    'mt-1',
                  )}
                >
                  {attr.attribute_name || attr.global_attribute_name}
                  {attr.is_system_attribute ? (
                    <FontAwesomeIcon icon={fsaSettings} className="ml-2" />
                  ) : null}
                </Col>
              </Row>
              <hr className="my-0 mr-1" />
            </div>
          ))
        )}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  scrollableDiv: {
    overflow: 'scroll',
    maxHeight: '700px',
  },
  selectedRow: {
    backgroundColor: backgroundGrey2,
  },
  globalRow: {
    color: blue,
  },
  resultsText: {
    color: darkGrey,
  },
});

export default AttributeSplitView;
